@import "../../../vars";

.lifter-index {
  height: 100%;

  .lifter-order-table {
    height: calc(100% - 50px);
  }

  .lifter-results {
    height: calc(100% - 50px);

    .table-wrapper {
      height: 100%;
    }
  }

  .lifter-info,
  .eligible-records {
    padding-bottom: 100px;
    overflow: scroll;
    height: calc(100% - 40px);
  }

  .eligible-records {
    .record-title {
      font-weight: bold;
      margin-bottom: 30px;
      font-size: 18px;
    }
  }

  .info-row {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    label {
      margin-right: 8px;
    }
  }

  .division-row {
    display: flex;
    flex-direction: row;
  }

  .attempt-table {
    td,
    th {
      text-align: center;
    }

    svg {
      height: 30px;
      width: 30px;
    }

    svg.good {
      fill: $green;
    }

    svg.bad {
      fill: $red;
    }

    .fault-cards {
      display: flex;

      svg {
        margin-right: 6px;
      }

      .fault-card {
        height: 30px;
        width: 30px;
        margin: 0 8px;

        &.red {
          background-color: $red;
        }

        &.blue {
          background-color: $blue;
        }

        &.yellow {
          background-color: $yellow;
        }
      }
    }

    .medium.table-cell {
      svg {
        height: 22px;
        width: 22px;
      }

      .fault-card {
        height: 22px;
        width: 22px;
        margin: 0 6px;
      }
    }

    .small.table-cell {
      svg {
        height: 16px;
        width: 16px;
      }

      .fault-card {
        height: 16px;
        width: 16px;
        margin: 0 4px;
      }
    }
  }
}

.large {
  .attempt-table {
    height: calc(100% - 50px);
  }
}

.medium {
  .attempt-table {
    height: calc(100% - 50px);
  }
}

.small {
  .attempt-table {
    height: calc(100% - 50px);
  }
}
