@import "./vars";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// disable body scroll when modal is open
// https://github.com/reactjs/react-modal#body-class
.ReactModal__Body--open {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: $darkestgray;
  color: $lightestgray;
  font-family: $regular-font;
  font-size: 14px;
  min-width: 320px;

  .version-info {
    margin: 20px;
  }

  input {
    font-family: $regular-font;
    font-size: 14px;
  }

  .app {
    padding: 0 20px 20px;

    &.small {
      padding: 0 10px 10px;
    }

    &.medium {
      padding: 0 15px 15px;
    }

    .app-error {
      font-size: 24px;
      display: flex;
      justify-content: center;
      padding: 60px 20px;
    }

    .meets {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .about-link {
        margin: 40px 0 20px;
        font-size: 24px;
        text-align: center;
      }

      .new-meet-button {
        margin-top: 20px;
      }

      .facebook-link {
        margin: 40px;
        svg {
          height: 40px;
          width: 40px;
          fill: $lightgray;
        }
      }

      .about-us {
        font-size: 22px;
        max-width: 600px;
        margin-top: 20px;
      }

      .table {
        width: 100%;
        margin-bottom: 40px;
      }

      .file-upload {
        margin: 20px;
      }

      .features-table {
        svg {
          height: 20px;
          width: 20px;
          fill: $lightgray;
        }
      }

      .table-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 50vw;
          max-width: 280px;
        }
      }

      .meets-table-table-wrapper {
        width: 95vw;
        max-width: 600px;
      }

      .meets-relay-server-table-table-wrapper {
        max-width: 95vw;
        overflow: scroll;
      }

      .meets-table-collapsed {
        height: 1200px;
        overflow: hidden;
      }

      .meet-table-show-more {
        position: relative;
        bottom: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 1)
        );
        button {
          font-size: 20px;
          width: 85vw;
          max-width: 400px;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .not-found {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
    }

    .auth-required {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      text-align: center;
      padding-top: 20vmin;

      button {
        margin-top: 12px;
      }
    }

    @media (max-width: 500px) {
      .auth-required {
        font-size: 32px;

        button {
          margin-top: 12px;
          font-size: 22px;
          padding: 12px 16px;
          height: auto;
        }
      }
    }

    .home-nav-wrapper {
      position: fixed;
      z-index: 180;
      top: 0;
      left: 0;
      right: 0;
      height: 48px;
      overflow: hidden;
      transition: box-shadow 0.3s linear;

      .homepage-nav-bar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon {
          position: fixed;
          left: 20px;
          top: 13.5px;
        }

        .homepage-nav-logo {
          width: 200px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-top: -25px;
          transform: translate(100vw, 0);
          transition: transform 0.3s linear;
          .loading-plate {
            display: none;
          }

          .layer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }

      &.scrolled {
        background-color: $darkestgray;
        box-shadow: 0px 0px 0px 1px $lightestgray;
        .homepage-nav-bar {
          .homepage-nav-logo {
            transform: none;
          }
        }
      }
    }

    .meet {
      height: 100%;

      .header {
        display: flex;
        align-items: center;
        height: 50px;
        position: relative;

        .sync-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 6px;

          svg {
            width: 18px;
            height: 18px;
            fill: $lightestgray;

            &.sync-active {
              fill: $green;
            }

            &.sync-disabled {
              fill: $red;
            }
          }
        }

        .meet-name {
          padding: 0 12px;
          font-weight: bold;
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100vw - 42px);
          display: flex;
        }
      }
    }
  }
}

hr {
  border: none;
  border-top: 1px solid $white;
}

a {
  color: $white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    text-decoration: underline;
  }
}

table.table {
  border: 1px solid $bordercolor;
  border-collapse: collapse;

  thead {
    th {
      background-color: $table-header-color;
    }
  }

  tr {
    th,
    td {
      border: 1px solid darkgray;
      padding: 10px;
    }
  }
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
textarea {
  border: none;
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  background-color: $lightestgray;
  color: $darkestgray;
}

button,
input[type="submit"],
input[type="button"],
.button-link {
  border-radius: 4px;
  border: none;
  padding: 0 8px;
  font-size: 14px;
  background-color: $darkgray;
  cursor: pointer;
  white-space: nowrap;
  height: 36px;
  color: $lightestgray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: darken($darkgray, 8%);
    svg {
      fill: #cccccc;
    }
  }

  &:disabled {
    color: gray;

    svg {
      fill: gray;
    }

    svg {
      fill: gray;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    fill: $lightestgray;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

label {
  font-weight: bold;
}

.tabs {
  border-bottom: 1px solid $lightestgray;
  margin: 0 0 10px;
  padding: 0 12px;

  .tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &.active {
      background-color: $darkestgray;
      border-color: $lightestgray;
      border-radius: 5px 5px 0 0;
      text-decoration: none;
    }
  }
}

@media print {
  @page {
    // margin: 0.5cm;
  }

  body {
    min-width: 1024px;

    .app {
      margin: 0px;
      padding: 0px;
      border: none;
      height: auto;
      overflow: visible;

      .meet {
        height: auto;

        .header {
          display: none;
        }
      }
    }
  }
}

.navigation {
  button.icon {
    cursor: pointer;
    background-color: transparent;
    padding: 0px;

    svg {
      height: 20px;
      width: 20px;
      fill: #eeeeee;
    }
  }
}

.navigation-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
}

.navigation-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -260px;
  width: 260px;
  z-index: 200;
  background-color: $darkestgray;
  border-right: 1px solid $lightestgray;
  overflow-y: auto;
  color: $lightestgray;

  // time here should match closeTimeoutMS prop on Modal component
  transition: all 400ms ease-in-out;

  &.navigation-modal-after-open {
    left: 0px;
  }
  &.navigation-modal-before-close {
    left: -260px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      fill: $lightestgray;
    }
  }

  .content {
    font-size: 20px;
    padding: 30px;
    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-top: 10px;

        ul.platform {
          li {
            margin-left: 12px;
          }
        }
      }
    }
  }
}

@keyframes load-plate-right-one {
  0% {
    transform: translatex(100vw);
  }
  25% {
    transform: translatex(100vw);
  }
  50% {
    transform: translatex(100vw);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-plate-right-two {
  0% {
    transform: translatex(100vw);
  }
  25% {
    transform: translatex(100vw);
  }
  50% {
    transform: translatex(0);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-plate-right-three {
  0% {
    transform: translatex(100vw);
  }
  25% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(0);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-plate-left-one {
  0% {
    transform: translatex(-100vw);
  }
  25% {
    transform: translatex(-100vw);
  }
  50% {
    transform: translatex(-100vw);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-plate-left-two {
  0% {
    transform: translatex(-100vw);
  }
  25% {
    transform: translatex(-100vw);
  }
  50% {
    transform: translatex(0);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-plate-left-three {
  0% {
    transform: translatex(-100vw);
  }
  25% {
    transform: translatex(0);
  }
  50% {
    transform: translatex(0);
  }
  75% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(0);
  }
}

@keyframes load-name {
  0% {
    transform: translatey(-100vh);
  }
  100% {
    transform: translatey(0);
  }
}

.loading-plate {
  animation-duration: 2500ms;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}

.loop {
  .loading-plate {
    animation-iteration-count: infinite;
  }
}

.loading-plate.right {
  transform: translate(100vw, 0);
}

.loading-plate.left {
  transform: translate(-100vw, 0);
}

.loading-plate.right.plate-one {
  animation-name: load-plate-right-one;
}

.loading-plate.right.plate-two {
  animation-name: load-plate-right-two;
}

.loading-plate.right.plate-three {
  animation-name: load-plate-right-three;
}

.loading-plate.left.plate-one {
  animation-name: load-plate-left-one;
}

.loading-plate.left.plate-two {
  animation-name: load-plate-left-two;
}

.loading-plate.left.plate-three {
  animation-name: load-plate-left-three;
}

.logo {
  width: 50vmin;
  min-width: 300px;
  max-width: 600px;
  height: 25vmin;
  min-height: 150px;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.logo.animate {
  // transform: translateZ(0);
  animation-name: load-name;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: translate(0, -100vh);
  animation-delay: 0;

  .loading-plate {
    animation-delay: 1000ms;
  }
}

.loading-wrapper {
  height: 100vmin;
  min-height: 300px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-wrapper {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  height: 30vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
